<template>
    <div class="form">
        <div class="background background_blue background_vacancy">
            <div class="background__image background__image_vacancy"></div>
        </div>

        <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
            <div class="content">
                <div class="content__container vacancy__container">
                    <div class="vacancy__title">{{ 'v_title' | localize }}</div>

                    <div class="form__group">
                        <v-select
                            v-model="city"
                            :items="cities"
                            :label="'v_city' | localize"
                            :placeholder="'v_cities' | localize"
                            append-icon="icon-arrow-down"
                            ref="city"
                            outlined
                        ></v-select>

                        <v-select
                            v-model="position"
                            :items="positions"
                            :label="'v_position' | localize"
                            :placeholder="'v_positions' | localize"
                            append-icon="icon-arrow-down"
                            ref="position"
                            outlined
                        ></v-select>
                    </div>

                    <div class="vacancy__found">
                        <span class="vacancy__found_text">{{ 'v_found' | localize }} {{ filterVacancies.length }}</span>
                        <span class="vacancy__found_text vacancy__found_hot" v-if="filterVacancies.filter(e => e.hot == 1).length > 0">{{ 'v_hot' | localize }} {{ filterVacancies.filter(e => e.hot == 1).length }}</span>
                    </div>

                    <div class="vacancies">
                        <div class="vacancies__head">
                            <div class="vacancies__row" :class="sortedClass('position')" @click="setSorting('position')">{{ "v_post" | localize }} <span class="vacancies__sort_icon"></span></div>
                            <div class="vacancies__row" :class="sortedClass('date')" @click="setSorting('date')">{{ "v_date" | localize }} <span class="vacancies__sort_icon"></span></div>
                            <div class="vacancies__row" :class="sortedClass('city')" @click="setSorting('city')">{{ "v_city2" | localize }} <span class="vacancies__sort_icon"></span></div>
                            <div class="vacancies__row" :class="sortedClass('salary')" @click="setSorting('salary')">{{ "v_salary" | localize }} <span class="vacancies__sort_icon"></span></div>
                        </div>
                        <div class="vacancies__item" :class="{vacancies__active: v.visible, vacancies__hot: v.hot}" @click="v.visible = !v.visible" v-for="(v, k) in filterVacancies" :key="k">
                            <div class="vacancies__row vacancies__row_position vacancies__largeText">{{ v.position }}</div>
                            <div class="vacancies__row vacancies__row_date">{{ v.date }}</div>
                            <div class="vacancies__row vacancies__row_city">{{ v.city }}</div>
                            <div class="vacancies__row vacancies__row_salary vacancies__largeText">{{ 'v_from' | localize }} {{ v.salary.toLocaleString() }} ₪</div>
                            <div class="vacancies__text">
                                <div v-html="v.text"></div>
                                <button type="button" class="button button__form" @click.stop="$store.commit('togglePopupText', { id: v.id })">{{ "v_button" | localize }}</button>
                            </div>
                        </div>
                        <div v-if="filterVacancies.length == 0">{{ "v_not_found" | localize }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup popup_active popup_info popup_lawyer" v-if="$store.state.popup.id">
            <div class="popup__overlay popup__overlay_active">
                <div class="popup__center" @click.self="$store.commit('togglePopupText')">
                    <div class="popup__window popup__window_blue">
                        <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
                        <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
                        <div class="popup__scrollcontainer ">
                            <div class="popup__scrollcontainer_inner">
                                <TextBlock :id="28" />
                            </div>
                        </div>
                        <div class="button__container" @click="$store.commit('togglePopupText')">
                            <router-link type="button" class="button button__form" :to="{name: 'Aspirant Questionnaire', params: {vacancy: parseInt($store.state.popup.id)}}">{{ 'v_tooltip1' | localize }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextBlock from '@/components/Text.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Vacancy',
  components: { TextBlock },
  mixins: [commonMixin],
  data: () => ({
    cities: [],
    positions: [],
    vacancies: [],
    city: null,
    position: null,
    selected: null,
    vacanciesList: [],
    sorting: null,
    v_found: null,
    v_hot: null,
    sorting: null,
    error: null
  }),
  created () {
    this.loadVacancies()
  },
  computed: {
    filterVacancies: function(){
      return this.sortVacancies(this.filterVacanciesByCity(this.filterVacanciesByPosition(this.vacancies)))
    }
  },
  methods: {
    filterVacanciesByCity (vacancies) {
      if (this.city == this.$options.filters.localize('v_cities')) return vacancies
      return vacancies.filter(v => !v.city.indexOf(this.city))
    },
    filterVacanciesByPosition (vacancies) {
      if (this.position == this.$options.filters.localize('v_positions')) return vacancies
      return vacancies.filter(v => !v.position.indexOf(this.position))
    },
    sortVacancies (vacancies) {
      if (!this.sorting) return vacancies
      return vacancies.sort(this.sortBy(this.sorting.field, this.sorting.asc))
    },
    sortedClass (field) {
        let sorterClass = 'vacancies__sort'
        if (this.sorting && this.sorting.field == field && this.sorting.asc == false) sorterClass += ' vacancies__sort_desc'
        if (this.sorting && this.sorting.field == field && this.sorting.asc == true) sorterClass += ' vacancies__sort_asc'
        return sorterClass
    },
    setSorting (field) {
      if (this.sorting && this.sorting.field == field && this.sorting.asc == true) { this.sorting = {field: 'order'}; return }
      this.sorting = { field: field, asc: this.sorting && this.sorting.field == field ? !this.sorting.acs : false }
    },
    submitHandler () {
    },
    loadVacancies () {
      this.axios.get('vacancies').then((response) => {
        response.data.forEach((e, idx) => { this.vacancies.push({...e, visible: false, order: idx}) })
        this.cities = response.data.map(e => e.city)
        this.positions = response.data.map(e => e.position)
        this.cities.unshift(this.$options.filters.localize('v_cities'))
        this.positions.unshift(this.$options.filters.localize('v_positions'))
        this.city = this.$options.filters.localize('v_cities')
        this.position = this.$options.filters.localize('v_positions')
      }).catch(() => {
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }
  }
}
</script>

<style scoped>

    .background__image.background__image_vacancy {
        opacity: 1;
        z-index: 1;
        position: relative;
        background-image: url("../../assets/images/common/vacancy/main.png");
    }
    .background__image.background__image_vacancy:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/images/common/vacancy/overlay.png");
        background-position: center;
        background-size: contain;
        background-repeat: repeat-y;
    }

    #app.light-theme .background__image.background__image_vacancy:before {
        background-image: url("../../assets/images/common/vacancy/overlay_light.png");
    }

</style>